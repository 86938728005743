import api from './api';

export const getProfile = async payload => {
  try {
    const response = await api.get(`/v1/people/${payload}`);
    return response;

  } catch (error) {
    throw new Error(error)
  }
};