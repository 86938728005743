import api from './api'

export const getBenefits = async (cpf, history) => {
  try {
    const response = await api.get(`v1/userbenefits/${cpf}`)
    return response
  } catch (err) {
    if (err.response.status === 401) {
      history.push('/logout')
      throw new Error('Sua sessão expirou, faça login e tente novamente')
    } else {
      throw new Error('Erro no servidor')
    }
  }
}
